// Font
$primary-color:#03225C;
$secondary-color: #074DCE;
$third-color: #7285D8;
$dark-color: white;
$error-color: red;
$neutral-color: #ADADB0;


// Navigation
$panel-color-hover: #F1F3FA;


// Home Page
$purple: #B494FF;
$blue: #074DCE;
$cyan: #00ACD1;
$cyan-2: #94ECFF;
$pink:#FF94F4;
$pink-2: #CB62C0;
$green: #45C6AA;
$yellow: #FFBC6E;
