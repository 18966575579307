@use "@angular/material" as mat;
@include mat.elevation-classes();
@include mat.app-background();

$moffi-palette: (
  50: white,
  /*e8eaf7*/ 100: #a0aadd,
  200: #7b88cf,
  300: #5e6dc5,
  400: #074dce,
  500: #074dce,
  //3242a4
  600: #074dce,
  700: #074dce,
  800: #074dce,
  900: #0640ac,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

// Définir les couleurs personnalisées
$custom-primary: mat.m2-define-palette($moffi-palette, 50, 50, 50);
$custom-accent: mat.m2-define-palette(mat.$m2-indigo-palette, A200, A100, A400);
$custom-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Définir la police personnalisée
@font-face {
  font-family: "Satoshi";
  src: url("/assets/fonts/Satoshi-Regular.woff") format("woff"),
    url("/assets/fonts/Satoshi-Regular.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Satoshi-Bold";
  src: url("/assets/fonts/Satoshi-Bold.woff") format("woff"),
    url("/assets/fonts/Satoshi-Bold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Satoshi-Medium";
  src: url("/assets/fonts/Satoshi-Medium.woff") format("woff"),
    url("/assets/fonts/Satoshi-Medium.woff2") format("woff2");
  font-display: swap;
}

// Utiliser la police personnalisée dans votre thème
$custom-typography: mat.m2-define-typography-config(
  $font-family: 'Satoshi, sans-serif'
);

// Créer le thème à partir des couleurs personnalisées
$custom-theme: mat.m2-define-light-theme(
  (
    typography: $custom-typography,
    color: (
      primary: $custom-primary,
      accent: $custom-accent,
      warn: $custom-warn,
    ),
  )
);

// Appliquer le thème à vos composants
@include mat.all-component-themes($custom-theme);
