
// Animation de flottement pour les images
@mixin floatingAnimation($animationName, $firstTopValue, $firstLeftValue, $secondTopValue, $secondLeftValue) {
    @keyframes #{$animationName} {
      0%, 100% {
        top: $firstTopValue;
        left: $firstLeftValue;
      }
      50% {
        top: $secondTopValue;
        left: $secondLeftValue;
      }
    }
  }

// Animation Fade
@keyframes fadeIn {
  /*from {
      transform: translateY(1rem);
      opacity: 0;
      }
  to {
     transform: translateY(0rem);
      opacity: 1;
      }*/ 
}

// Animation scale In
@keyframes scaleIn {
  /*0% {
      opacity: 0;
      transform: scale(0);
    }
  50% {
      opacity: 1;
      transform: scale(1.2);
    }
  100% {
      opacity: 1;
      transform: scale(1); 
  }*/
}

@keyframes simpleScaleIn {
  /*0% {
    opacity: 0;
    transform: scale(0);
  }
  25% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
      opacity: 1;
      transform: scale(1); 
  }*/
}


// Animation scale In
@keyframes scaleOut {
  0% {
      transform: scale(0.7);
    }
  50% {
     transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

// Scale In par la droite
@keyframes scaleInRight {
  0% {
      opacity: 0;
      transform: scale(0);
      transform-origin: 100% 0%; 
    }
  100% {
      opacity: 1;
      transform: scale(1); 
      transform-origin: 100% 0%; 
  }
}

// Scale In par la gauche
@keyframes scaleInLeft {
  0% {
      opacity: 0;
      transform: scale(0);
      transform-origin: 0% 0%; 
    }
  100% {
      opacity: 1;
      transform: scale(1); 
      transform-origin: 0% 0%; 
  }
}

// Animation simulation clic sur un bouton
@keyframes clicButton {
  0% {
      opacity: 0;
    }
  15% {
    opacity: 1;
  }
}

@keyframes mooveCursor {
  0% {
    top: 66%;
    left: 85%;
    }
  100% {
    top: 70%;
    left: 71%;
  }
}

@keyframes mooveMobile {
  0% {
    opacity: 0;
    top: 10%;
    left: -18%;
    }
  100% {
    opacity: 1;
    top: 5%;
    left: -13%;
  }
}

@keyframes dropAndFadeIn {
  /*from {
    opacity: 0;
    transform: translateY(-50px); // Ajustez selon l'effet désiré
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }*/
}

@property --angle {
  syntax: "<angle>";
  initial-value: -180deg;
  inherits: false;
}

