@use '@angular/material' as mat;
@include mat.elevation-classes();
@include mat.app-background();


$moffi-palette: (
  10: #FFFFFF,
  50: #E4E6F5,
  100: #c6cbeb, 
  200: #A1ABE2,
  300: #7285D8,
  400: #074dce,
  500: #074dce,
  600: #074dce,
  700: #074dce,
  800: #03225C,
  900: #EF5F5F,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);


$moffi-palette-dark: (
  10: #FFFFFF,
  50: #FFFFFF,
  100: #FFFFFF, 
  200: #FFFFFF80,
  300: #FFFFFF,
  400: #074dce,
  500: #F1F3FA,
  600: #E4E6F5,
  700: #FFFFFF,
  800: #FFFFFF,
  900: #FFFFFF,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);



// Définir les couleurs personnalisées
$custom-primary: mat.m2-define-palette($moffi-palette);
$custom-accent: mat.m2-define-palette(mat.$m2-indigo-palette, A200, A100, A400); 
$custom-warn: mat.m2-define-palette(mat.$m2-red-palette);


// Définir la police personnalisée
@font-face {
    font-family: 'Satoshi';
    src: url('/fonts/Satoshi-Regular.woff') format('woff'), 
         url('/fonts/Satoshi-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Satoshi-Bold';
  src: url('/fonts/Satoshi-Bold.woff') format('woff'), 
       url('/fonts/Satoshi-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'Satoshi-Medium';
  src: url('/fonts/Satoshi-Medium.woff') format('woff'), 
       url('/fonts/Satoshi-Medium.woff2') format('woff2');
}
// Utiliser la police personnalisée dans votre thème
$custom-typography: mat.m2-define-typography-config(
  $font-family: 'Satoshi, sans-serif'
);

// Créer le thème à partir des couleurs personnalisées
$custom-theme: mat.m2-define-light-theme(
  (
    typography: $custom-typography,
    color: (
      primary: $custom-primary,
      accent: $custom-accent,
      warn: $custom-warn
    )
  )
);


// Appliquer le thème à vos composants
@include mat.all-component-themes($custom-theme);

.moffi-form-panel {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
}

.moffi-field-label {
  font-family: "Satoshi-Medium", sans-serif;
  font-size: 1rem;
  color: mat.m2-get-color-from-palette($moffi-palette, 800) !important;
  margin-left: 5px;
}

.moffi-field-label-dark {
  font-family: "Satoshi-Medium", sans-serif;
  font-size: 1rem;
  color: mat.m2-get-color-from-palette($moffi-palette-dark, 800) !important;
  margin-left: 5px;
}

// Additional custom styles for the mat-form-field
.moffi-field {
  --mat-field-border-radius-right: 0.5rem;

  .submit-button {
    background-color: mat.m2-get-color-from-palette($moffi-palette, 300);
    border: none;
    height: calc(98% - (var(--mat-form-field-subscript-text-line-height)));
    width: 100%;
    padding: 0rem 1.2rem;
    color: white;
    font-family: "Satoshi-Medium", sans-serif;
    font-size: 1rem;
    cursor: pointer;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .submit-button:hover {
    background-color: mat.m2-get-color-from-palette($moffi-palette, 300);
  }
  .submit-button:active {
    background-color: mat.m2-get-color-from-palette($moffi-palette, 200);
  }

  .number-input-arrows {
    color: mat.m2-get-color-from-palette($moffi-palette, 100);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;

    .input-arrow {
      font-size: 20px; 
      height: 20px; 
      width: 20px; 
      cursor: pointer;
    }
  }
  .mat-mdc-form-field-icon-suffix {
    padding: 0 0 0 4px;
    position: absolute;
    right: 0px;
  }


  .mdc-notched-outline {
    background-color: mat.m2-get-color-from-palette($moffi-palette, 10);
    border-radius: 0.5rem;
    z-index: 0 !important;
  }

  // Border Radius
  .mdc-notched-outline__leading {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .mdc-notched-outline__trailing {
    border-top-right-radius: var(--mat-field-border-radius-right) !important;
    border-bottom-right-radius: var(--mat-field-border-radius-right) !important;
  }

  // Border color default
  .mdc-notched-outline > * {
    border-color: mat.m2-get-color-from-palette($moffi-palette, 50) !important;
  }
  // Border color on focus
  .mdc-text-field--focused .mdc-notched-outline > * {
    border-color: mat.m2-get-color-from-palette($moffi-palette, 300)  !important;
  }
  // Border color error
  .mdc-text-field--invalid .mdc-notched-outline > * {
    border-color: mat.m2-get-color-from-palette($moffi-palette, 900) !important;
  }
  // Bordure color on hover
  .mdc-text-field:not(.mdc-text-field--invalid):hover .mdc-notched-outline > * {
    border-color: mat.m2-get-color-from-palette($moffi-palette, 300) !important;
  }

  // Field label selected
  .mdc-floating-label--float-above
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover 
  .mdc-floating-label, .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover 
  .mdc-floating-label--float-above,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label, .mdc-text-field--outlined:not(.mdc-text-field--disabled) 
  .mdc-floating-label--float-above
  {
    color: mat.m2-get-color-from-palette($moffi-palette, 300) !important; 
  }
  .mdc-floating-label--invalid {
    color: mat.m2-get-color-from-palette($moffi-palette, 900) !important; 
  }
  .mdc-floating-label--invalid + span {
    color: mat.m2-get-color-from-palette($moffi-palette, 900) !important; 
  }
  
  // Placeholder color
  .mat-mdc-input-element::placeholder {
      color: mat.m2-get-color-from-palette($moffi-palette, 200) !important; 
  }
  .mat-mdc-select-placeholder {
    color: mat.m2-get-color-from-palette($moffi-palette, 200) !important; 
  }
  .mat-mdc-select-disabled .mat-mdc-select-placeholder {
    color: #ADADB0 !important;
  }

  .icon-arrow {
    color: mat.m2-get-color-from-palette($moffi-palette, 200) !important;
  }
  .mat-mdc-select-arrow {
     display: none;
  }

  // Description color
  .mat-mdc-form-field-hint {
    color: mat.m2-get-color-from-palette($moffi-palette, 300); 
  }
  .mat-mdc-form-field-hint--invalid {
    color: mat.m2-get-color-from-palette($moffi-palette, 900); 
  }

  // Input color
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: mat.m2-get-color-from-palette($moffi-palette, 800);
  }
  .mat-mdc-select {
    color: mat.m2-get-color-from-palette($moffi-palette, 800);
  }

  .mat-form-field-input-prefix-icon {
    color: mat.m2-get-color-from-palette($moffi-palette, 300) !important;
  }

  // Icone croix pour vider le champ
  .mat-form-field-input-cross {
    color: mat.m2-get-color-from-palette($moffi-palette, 300) !important; 
    cursor: pointer;
  }

  // Icone error 
  .mat-form-field-input-error {
    color: mat.m2-get-color-from-palette($moffi-palette, 900) !important;
  }

  // Chips color (multiselect combobox)
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    background-color: white;
    border: 1px solid mat.m2-get-color-from-palette($moffi-palette, 100) !important;
    border-radius: 0.5rem;
  }
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
    color: mat.m2-get-color-from-palette($moffi-palette, 800);
  }

  .mat-mdc-form-field-error {
    color: mat.m2-get-color-from-palette($moffi-palette, 900);
    font-size: var(--mat-form-field-subscript-text-size);
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    gap: 0.2rem;

    .mat-icon {
      font-size: 13.33px;
      height: 15px;
      width: 13.33px;
    }
  }


  // Checkbox
  --mdc-checkbox-unselected-icon-color: #c6cbeb;
  --mdc-checkbox-unselected-pressed-icon-color: #A1ABE2;
  --mdc-checkbox-unselected-hover-icon-color: #A1ABE2;
  --mdc-checkbox-unselected-focus-icon-color: #A1ABE2;
  --mdc-checkbox-selected-icon-color: #074DCE;
  --mdc-checkbox-selected-pressed-icon-color: #074DCE;
  --mdc-checkbox-selected-hover-icon-color: #074DCE;
  --mdc-checkbox-selected-focus-icon-color: #074DCE;

  // Ripple
  --mdc-checkbox-selected-pressed-state-layer-color: #074DCE;
  --mdc-checkbox-unselected-pressed-state-layer-color: #074DCE;
  --mdc-checkbox-selected-hover-state-layer-color: #074DCE;
  --mdc-checkbox-unselected-hover-state-layer-color: #074DCE;
  --mdc-checkbox-unselected-focus-state-layer-color: #074DCE;
  --mdc-checkbox-selected-focus-state-layer-color: #074DCE;

  .mat-checkbox-error {
    --mdc-checkbox-unselected-icon-color: #D3153B;
    --mdc-checkbox-unselected-pressed-icon-color: #D3153B;
    --mdc-checkbox-unselected-hover-icon-color: #D3153B;
    --mdc-checkbox-unselected-focus-icon-color: #D3153B;
    --mdc-checkbox-selected-icon-color: #D3153B;
    --mdc-checkbox-selected-pressed-icon-color: #D3153B;
    --mdc-checkbox-selected-hover-icon-color: #D3153B;
    --mdc-checkbox-selected-focus-icon-color: #D3153B;
    // Ripple
    --mdc-checkbox-selected-pressed-state-layer-color: #D3153B;
    --mdc-checkbox-unselected-pressed-state-layer-color: #D3153B;
    --mdc-checkbox-selected-hover-state-layer-color: #D3153B;
    --mdc-checkbox-unselected-hover-state-layer-color: #D3153B;
    --mdc-checkbox-unselected-focus-state-layer-color: #D3153B;
    --mdc-checkbox-selected-focus-state-layer-color: #D3153B;
  }

  .moffi-checkbox-label {
    text-align: left; 
    display: flex;
    color: mat.m2-get-color-from-palette($moffi-palette, 800);
  }
}




// Additional custom styles for the mat-form-field
.darkMode {
  --mat-form-field-error-text-color: white;
  --mat-field-border-radius-right: 0.5rem;

  .submit-button {
    background-color: white;
    border: none;
    width: 100%;
    padding: 0rem 1.2rem;
    height: var(--mat-form-field-container-height);
    color: #074dce;
    font-family: "Satoshi-Medium", sans-serif;
    font-size: 1rem;
    cursor: pointer;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .submit-button:hover {
    background-color: mat.m2-get-color-from-palette($moffi-palette-dark, 500);
  }
  .submit-button:active {
    background-color: mat.m2-get-color-from-palette($moffi-palette-dark, 600);
  }
  .number-input-arrows {
    color: mat.m2-get-color-from-palette($moffi-palette-dark, 100);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;

    .input-arrow {
      font-size: 20px; 
      height: 20px; 
      width: 20px; 
      cursor: pointer;
    }
  }
  .mat-mdc-form-field-icon-suffix {
    padding: 0 0 0 4px;
    position: absolute;
    right: 0px;
  }

  .mdc-notched-outline {
    background: none;
    border-radius: 0.5rem;
    z-index: 0 !important;
  }

  // Border Radius
  .mdc-notched-outline__leading {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .mdc-notched-outline__trailing {
    border-top-right-radius: var(--mat-field-border-radius-right) !important;
    border-bottom-right-radius: var(--mat-field-border-radius-right) !important;
  }

  // Border color default
  .mdc-notched-outline > * {
    border-color: mat.m2-get-color-from-palette($moffi-palette-dark, 50) !important;
  }
  // Border color on focus
  .mdc-text-field--focused .mdc-notched-outline > * {
    border-color: mat.m2-get-color-from-palette($moffi-palette-dark, 300)  !important;
  }
  // Border color error
  .mdc-text-field--invalid .mdc-notched-outline > * {
    border-color: mat.m2-get-color-from-palette($moffi-palette-dark, 900) !important;
  }
  // Background color on hover
  .mdc-text-field:not(.mdc-text-field--focused):hover .mdc-notched-outline > * {
    background-color: #FFFFFF26 !important;
  }

  // Field label selected
  .mdc-floating-label--float-above
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover 
  .mdc-floating-label, .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover 
  .mdc-floating-label--float-above,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label, .mdc-text-field--outlined:not(.mdc-text-field--disabled) 
  .mdc-floating-label--float-above
  {
    color: mat.m2-get-color-from-palette($moffi-palette-dark, 300) !important; 
  }
  .mdc-floating-label--invalid {
    color: mat.m2-get-color-from-palette($moffi-palette-dark, 300) !important; 
  }
  .mdc-floating-label--invalid + span {
    color: mat.m2-get-color-from-palette($moffi-palette-dark, 300) !important; 
  }
  
  // Placeholder color
  .mat-mdc-input-element::placeholder {
      color: mat.m2-get-color-from-palette($moffi-palette-dark, 200) !important; 
  }
  .mat-mdc-select-placeholder {
    color: mat.m2-get-color-from-palette($moffi-palette-dark, 200) !important; 
  }
  .mat-mdc-select-disabled .mat-mdc-select-placeholder {
    color: #ADADB0 !important;
  }
  .icon-arrow {
    color: mat.m2-get-color-from-palette($moffi-palette-dark, 300) !important;
  }
  .mat-mdc-select-arrow {
     display: none;
  }

  // Description color
  .mat-mdc-form-field-hint {
    color: mat.m2-get-color-from-palette($moffi-palette-dark, 300); 
  }
  .mat-mdc-form-field-hint--invalid {
    color: mat.m2-get-color-from-palette($moffi-palette-dark, 900); 
  }

  // Input color
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: mat.m2-get-color-from-palette($moffi-palette-dark, 800);
  }
  .mat-mdc-select {
    color: mat.m2-get-color-from-palette($moffi-palette-dark, 800);
  }

  .mat-form-field-input-prefix-icon {
    color: mat.m2-get-color-from-palette($moffi-palette-dark, 300) !important;
  }

  // Icone croix pour vider le champ
  .mat-form-field-input-cross {
    color: mat.m2-get-color-from-palette($moffi-palette-dark, 300) !important; 
    cursor: pointer;
  }

  // Icone error 
  .mat-form-field-input-error {
    color: mat.m2-get-color-from-palette($moffi-palette-dark, 900) !important;
  }

  // Chips color (multiselect combobox)
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    background-color: white;
    border: 1px solid mat.m2-get-color-from-palette($moffi-palette-dark, 100) !important;
    border-radius: 0.5rem;
  }
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
    color: mat.m2-get-color-from-palette($moffi-palette-dark, 800);
  }

  .mat-mdc-form-field-error {
    color: mat.m2-get-color-from-palette($moffi-palette-dark, 900);
    font-size: var(--mat-form-field-subscript-text-size);
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    gap: 0.2rem;

    .mat-icon {
      font-size: 13.33px;
      height: 15px;
      width: 13.33px;
    }
  }

  // Checkbox
  --mdc-checkbox-unselected-icon-color: white;
  --mdc-checkbox-unselected-pressed-icon-color: white;
  --mdc-checkbox-unselected-hover-icon-color: white;
  --mdc-checkbox-unselected-focus-icon-color: white;
  --mdc-checkbox-selected-icon-color: white;
  --mdc-checkbox-selected-pressed-icon-color: white;
  --mdc-checkbox-selected-hover-icon-color: white;
  --mdc-checkbox-selected-focus-icon-color: white;
  --mdc-checkbox-selected-checkmark-color: #333333;

  // Ripple
  --mdc-checkbox-selected-pressed-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
  --mdc-checkbox-selected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-selected-focus-state-layer-color: white;

  .mat-checkbox-error {
    --mdc-checkbox-unselected-icon-color: white;
    --mdc-checkbox-unselected-pressed-icon-color: white;
    --mdc-checkbox-unselected-hover-icon-color: white;
    --mdc-checkbox-unselected-focus-icon-color: white;
    --mdc-checkbox-selected-icon-color: white;
    --mdc-checkbox-selected-pressed-icon-color: white;
    --mdc-checkbox-selected-hover-icon-color: white;
    --mdc-checkbox-selected-focus-icon-color: white;
    // Ripple
    --mdc-checkbox-selected-pressed-state-layer-color: white;
    --mdc-checkbox-unselected-pressed-state-layer-color: white;
    --mdc-checkbox-selected-hover-state-layer-color: white;
    --mdc-checkbox-unselected-hover-state-layer-color: white;
    --mdc-checkbox-unselected-focus-state-layer-color: white;
    --mdc-checkbox-selected-focus-state-layer-color: white;
  }

  .moffi-checkbox-label {
    text-align: left; 
    display: flex;
    color: mat.m2-get-color-from-palette($moffi-palette-dark, 800);
  }
}











